import { graphql } from 'gatsby'
import styled from 'styled-components'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import PageBanner from '../components/PageBanner'
import PageSection from '../components/PageSection'
import Section from '../components/Section'
import SuccessStoriesStory from '../components/SuccessStoriesStory'
import Wrapper from '../components/Wrapper'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix, urlPathJoin } from '../utils/locales'

export const query = graphql`
  query SuccessStories($contentful_id: String!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
      banner {
        ...PageBanner
      }
      sections {
        ...PageSection
      }
    }
    successStories: allContentfulSuccessStory(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: first_name, order: ASC }
    ) {
      edges {
        node {
          id
          first_name
          last_name
          quote {
            quote
          }
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 60, height: 60, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`

const StoryWrapper = styled(Wrapper)`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 var(--spacing-md);
  gap: var(--spacing-md);
`

const SuccessStories = ({
  pageContext: { translatedPages },
  data: {
    contentfulReactPage: { contentful_id, slug, banner, sections },
    successStories,
  },
}) => {
  const { locale } = useIntl()
  const pageBasePath = urlPathJoin(getLocalePathPrefix(locale), slug)

  const storyNodes = successStories.edges.map((edge) => edge.node)

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <PageBanner banner={banner} />
      <Section
        className="section"
        align="center"
        bgColor="Light Blue"
        hasComponent>
        <Wrapper>
          <StoryWrapper>
            {storyNodes.map((story) => (
              <SuccessStoriesStory
                key={story.id}
                parentBasePath={pageBasePath}
                story={story}
              />
            ))}
          </StoryWrapper>
        </Wrapper>
      </Section>
      <PageSection
        contentfulId={contentful_id}
        successStoriesPath={pageBasePath}
        sections={sections}
      />
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default SuccessStories
