import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { useIntl } from '../utils/IntlContext'
import { formatFullName, urlPathJoin } from '../utils/locales'
import media from '../utils/media'
import HoverCard from './HoverCard'

const SuccessContainer = styled(HoverCard)`
  text-align: center;
  position: relative;
  background-color: var(--color-background-white);
  border-radius: var(--border-radius);
  padding: 24px;
  position: relative;

  flex: 0 0 100%;

  ${media.sm`
    flex: 0 0 calc(50% - (var(--spacing-md) / 2));
  `}

  ${media.md`
    flex: 0 0 calc(33.33% - (2 * var(--spacing-md) / 3));
  `}

  p:first-of-type {
    margin-bottom: 24px;
    color: var(--color-typography-medium) !important;
    min-height: 200px;
  }

  h3 {
    color: var(--color-typography-dark) !important;
  }

  span {
    font-size: 14px;

    strong {
      color: var(--color-primary);
    }
  }
`

const Avatar = styled(GatsbyImage)`
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  margin: 0 auto 16px auto;
`

const SuccessStoriesStory = ({ parentBasePath, story }) => {
  const { t } = useIntl()

  const fullName = formatFullName(story.first_name, story.last_name)

  return (
    <SuccessContainer to={urlPathJoin(parentBasePath, story.first_name.toLowerCase())}>
      <p>&quot;{story.quote.quote}&quot;</p>
      <Avatar
        image={getImage(story.photo.localFile)}
        alt={fullName}
      />
      <p>
        <strong>{fullName}</strong>
      </p>
      <span>
        <strong>{t('read_my_story')}</strong>
      </span>
    </SuccessContainer>
  )
}

export default SuccessStoriesStory
